import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import './registerServiceWorker'
import router from './router'
import "./css/styles.css";
import StoragePlugin from 'vue-web-storage'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import VueMatomo from 'vue-matomo';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
 
const app = createApp(App).use(router).use(store)

.use(VueMatomo, {
  host: 'http://matomo.grifc.fr/',
  siteId: 4,
})
// .use(SimpleTypeahead)
.use(StoragePlugin, {
  prefix: 'sess_', // Préfixe des clés de stockage, par défaut 'app_'
  drivers: ['session', 'local'], // Type de stockage utilisé
});;
 app.component('EasyDataTable', Vue3EasyDataTable);
 app.config.globalProperties.$swal = Swal;
 app.mount('#app');
// Vérification de l'initialisation de Matomo avant d'envoyer des données
if (window._paq) {
  window._paq.push(['trackPageView']); // Pour suivre la vue de la page
} else {
  console.error('Matomo (Piwik) n’est pas initialisé correctement.');
}
