import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Fonction pour retourner l'état initial
const getDefaultState = () => {
  return {
    gDefaut:{
      gSession: null, // Stocke l'ID de la session pour le panier
      gTopHeader:'',
      gGpi_adh: '',
      gPic_adh: '',
      gGart_adh: 'N',
      gCod_metDefaut: 'VL',
      gLinkingTargetTypeDefaut: 'P',
      gPortailDefaut: 'portailVL',
      gLibellePortailDefaut: 'Pièces Auto',
      gIconPortailDefaut: 'piece',
    },
    gInfoPanier:{
      gTotalQtePanier: '',
      gTotalMntPanier: '',
    },
    gInfoVehicule:{
      gKtype: '',
      gImmat: '',
      gVin: '',
      gTyp: 'I',
      gTabInfoVeh: '',
    }
  };
};

export default createStore({
  state: getDefaultState(), // Initialise l'état avec la fonction
  getters: {
    
  },
  mutations: {
    // Mutation pour réinitialiser les informations du véhicule
    resetgInfoVehiculeState(state) {
      state.gInfoVehicule = {
        gKtype: '',
        gImmat: '',
        gVin: '',
        gTyp: 'I',
        gTabInfoVeh: ''
      };
    },
    // Mutation pour réinitialiser les informations du panier
    resetgInfoPanierState(state) {
      state.gInfoPanier = {
        gTotalQtePanier: '',
        gTotalMntPanier: ''
      };
    },
    // Mutation pour réinitialiser les informations de connexion par defaut
    resetgDefautState(state) {
      state.gDefaut = {
        gSession: null, // Stocke l'ID de la session pour le panier
        gTopHeader:'',
        gGpi_adh: '',
        gPic_adh: '',
        gGart_adh: 'N',
        gCod_metDefaut: 'VL',
        gLinkingTargetTypeDefaut: 'P',
        gPortailDefaut: 'portailVL',
        gLibellePortailDefaut: 'Pièces Auto',
        gIconPortailDefaut: 'piece',
      };
    },
     // Mutations pour mettre à jour les informations du panier
     setgTotalQtePanier(state, value) {
      state.gInfoPanier.gTotalQtePanier = value;
    },
    setgTotalMntPanier(state, value) {
      state.gInfoPanier.gTotalMntPanier = value;
    },
    // Mutations pour mettre à jour les valeurs dans gDefaut
    setgLibellePortailDefaut(state, value) {
      state.gDefaut.gLibellePortailDefaut = value;
    },
    setgSession(state, value) {
      state.gDefaut.gSession = value;
    },
    setgLinkingTargetTypeDefaut(state, value) {
      state.gDefaut.gLinkingTargetTypeDefaut = value;
    },
    setgPortailDefaut(state, value) {
      state.gDefaut.gPortailDefaut = value;
    },
    setgCod_metDefaut(state, value) {
      state.gDefaut.gCod_metDefaut = value;
    },
    // Mutations pour mettre à jour les informations du véhicule
    setgKtype(state, value) {
      state.gInfoVehicule.gKtype = value;
    },
    setgTyp(state, value) {
      state.gInfoVehicule.gTyp = value;
    },
    setgImmat(state, value) {
      state.gInfoVehicule.gImmat = value;
    },
    setgVin(state, value) {
      state.gInfoVehicule.gVin = value;
    },
    setgInfoVeh(state, value) {
      state.gInfoVehicule.gTabInfoVeh = value;
    },
    // Mutations pour mettre à jour les informations d'adhérent
    setgGpi_adh(state, value) {
      state.gDefaut.gGpi_adh = value;
    },
    setgPic_adh(state, value) {
      state.gDefaut.gPic_adh = value;
    },
    setgGart_adh(state, value) {
      state.gDefaut.gGart_adh = value;
    },
    setgTopHeader(state, value) {
      state.gDefaut.gTopHeader = value;
    },
    // Mutation pour réinitialiser tout l'état
    RESET_STATE(state) {
      // Remplace l'état actuel par l'état par défaut
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  modules: {
    
  },
  plugins: [createPersistedState()], // Persist le store avec vuex-persistedstate
});
